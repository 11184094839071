import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useWorkReportActions } from "../../../recoil/api/workReport";
import { REPORTTYPE } from "../../../JSON/enum/Dataenum";
import BreadCrumb from "../BreadCrumb";
import { ReactComponent as FileDeleteBtn } from "../../../img/file-delete-btn.svg";
import { useEffect } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { fileNameCheck, fileSizeCheck } from "../../../utils";

/* 추가된 코드 */
import { QuillEditor } from "../../toolbar/QuillEditor";
export const workReportmaxLength = 100;

const WorkReportWrite = () => {
  const navigate = useNavigate();
  const params = useParams();

  // 계정 상태 값
  const auth = useRecoilValue(authAtom);
  // 토스트 상태 값
  const [toast, setToast] = useRecoilState(toastAtom);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);

  // 파일 상태 값
  const [files, setFiles] = useState([]);

  // 파일 input 핸들러
  const onchangeFilesHandler = (event) => {
    event.preventDefault();
    if (event.target.files.length) {
      if (fileNameCheck(event) === false) {
        alert("파일명에 사용할 수 없는 특수문자가 들어가 있습니다.");
        return;
      }
      if (fileSizeCheck(event, 300) === false) {
        alert("첨부파일의 용량은 300MB를 넘길 수 없습니다.");
        return;
      }

      const newFiles = files
        .concat(Array.from(event.target.files))
        .map((ele, index) =>
          isNaN(ele?.id) ? { id: index, file: ele } : { ...ele, id: index }
        );
      setFiles(newFiles);
      setValue("file", null);
    }
  };

  // 파일 삭제 함수
  const removeFile = (index) => {
    const newFiles = [...files].filter((file) => file.id !== index);
    setFiles(newFiles);
  };

  // useForm
  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      editor: "",
      workreportYMD: dayjs().format("YYYY-MM-DD"),
    },
  });

  // 기본 양식 세팅
  const loadWorkReportForm = async () => {
    // const res = await workReportActions.getWorkReportView(docsn);

    const blocksFromHtml = `<p>${dayjs().format("MM")}월
    ${dayjs().format("DD")}일 ${
      REPORTTYPE.DAYILY == params?.type
        ? "일일 업무일지</p> <p>● 오늘 업무목표</p><p></p> <p>● 오늘 진행 사항</p><p></p> <p>● 내일 예정 사항</p><p></p>"
        : REPORTTYPE.WEEKILY == params?.type
        ? "주간 업무일지</p> <p>● 이번주 업무목표</p><p></p> <p>● 이번주 진행 사항</p><p></p> <p>● 다음주 예정 사항</p><p></p>"
        : REPORTTYPE.MONTHLIY == params?.type &&
          "월간 업무일지</p> <p>● 이번달 업무목표</p><p></p> <p>● 이번달 진행 사항</p><p></p> <p>● 다음달 예정 사항</p><p></p>"
    }`;

    setValue("editor", blocksFromHtml);
  };

  useEffect(() => {
    loadWorkReportForm();
  }, []);

  // 에디터 글자 저장용도
  const [editorTextLength, setEditorTextLength] = useState(0);

  // Submit 함수
  const workReportActions = useWorkReportActions();

  const onSubmit = async (data) => {
    if (editorTextLength < workReportmaxLength && files.length === 0) {
      setToast({
        ...toast,
        error: true,
        message: "100자 이상 기재와 첨부파일 등록을 부탁드립니다.",
      });
      return;
    } else if (editorTextLength < workReportmaxLength) {
      setToast({
        ...toast,
        error: true,
        message: "100자 이상 기재 부탁드립니다.",
      });
      return;
    } else if (files.length === 0) {
      setToast({
        ...toast,
        error: true,
        message: "첨부파일이 등록되지 않았습니다.",
      });
      return;
    }

    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    setToast({
      ...toast,
      on: true,
      message: "첨부파일 용량과 인터넷 속도에 따라 저장이 길어질 수 있습니다.",
    });

    let setTitle = "";
    if (REPORTTYPE.MONTHLIY == params?.type) {
      setTitle = "월간업무 보고";
    } else if (REPORTTYPE.WEEKILY == params?.type) {
      setTitle = "주간업무 보고";
    } else {
      setTitle = "일일업무 보고";
    }

    const req = {
      title: setTitle,
      contents: data.editor,
      YMD: data.workreportYMD,
      file: files,
      type: params?.type,
    };

    const res = await workReportActions.createWorkReport(req);

    if (res.status === 200) {
      setToast({ ...toast, on: true });
      navigate(`/${auth.accountType}/workreport`);
    } else if (res.status === 400) {
      setToast({
        ...toast,
        error: true,
        message: `${
          REPORTTYPE.DAYILY == params?.type
            ? "일일 "
            : REPORTTYPE.WEEKILY == params?.type
            ? "주간 "
            : REPORTTYPE.MONTHLIY == params?.type && "월간 "
        }업무일지는 ${
          REPORTTYPE.DAYILY == params?.type
            ? "일일 "
            : REPORTTYPE.WEEKILY == params?.type
            ? "주간 "
            : REPORTTYPE.MONTHLIY == params?.type && "월간 "
        }1개만 작성이 가능합니다.`,
      });
    } else {
      // setToast({ ...toast, error: true, message: "작성 실패" });
      alert(
        `작성 실패\n` +
          `title:${setTitle}\n` +
          `YMD:${data.workreportYMD}\n` +
          `type:${params?.type}\n` +
          `data:${res?.data}\n` +
          `status:${res?.status}\n` +
          `statusText:${res?.statusText}\n`
      );
    }

    setDoubleclickLoading(false);
  };

  return (
    <div className="substanceCont">
      <BreadCrumb
        workReport={
          REPORTTYPE.DAYILY == params?.type
            ? "일일 "
            : REPORTTYPE.WEEKILY == params?.type
            ? "주간 "
            : REPORTTYPE.MONTHLIY == params?.type && "월간 "
        }
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="table-responsive">
          <table className="writetable hide">
            <tbody>
              <tr>
                <th className="text-center-title zoom_font w-20">작성자</th>
                <td className="w-30">
                  <div id="divhashlist" className="zoom_font">
                    {auth.user.userName}
                  </div>
                </td>

                <th className="text-center-title zoom_font w-20">작성일</th>
                <td className="zoom_font">
                  <input
                    {...register("workreportYMD")}
                    className="write-date border-line mouse zoom_font"
                    type="date"
                    max={`${dayjs().format("YYYY-MM-DD")}`}
                  />
                </td>
              </tr>

              <tr style={{ display: "none" }}>
                <th className="text-center-title zoom_font">제목</th>
                <td colSpan="3" className="zoom_font">
                  <input
                    {...register("title")}
                    className="write-input border-line mouse zoom_font"
                    type="text"
                  ></input>
                </td>
              </tr>

              <tr>
                <th className="text-center-title zoom_font">
                  내용
                  <div>{`${editorTextLength}/${workReportmaxLength}`}</div>
                </th>
                <td colSpan="5" className="parent-scroll">
                  <Controller
                    name="editor"
                    control={control}
                    render={({ field: { value, onChange } }) =>
                      auth.user?.disabled_name === "시각장애" ||
                      auth.user?.sub_disabled_name === "시각장애" ? (
                        <Editor
                          apiKey={
                            "pwlbxd7c7x9lapnd8nwqidzm2gre8bazisoovnitdbojymrp"
                          }
                          onGetContent={(e) => {
                            let content = e.content;
                            content = content
                              .replace(/(<([^>]+)>)/gi, "")
                              .replace(/&lt;/gi, "1")
                              .replace(/&gt;/gi, "1")
                              .replace(/&nbsp;/gi, "1")
                              .replace(/&quot;/gi, "1")
                              .replace(/&amp;/gi, "1");

                            setEditorTextLength(content.length);
                          }}
                          value={value}
                          onEditorChange={onChange}
                          init={{
                            plugins: [
                              "table",
                              "link",
                              "autolink",
                              "contextmenu",
                            ],
                            language: "ko_KR",
                            selector: "div",
                            elementpath: false,
                            branding: false,
                            autolink: true,
                            toolbar:
                              "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | link",
                            contextmenu: "true",
                            menubar: "file insert view format table",
                          }}
                        />
                      ) : (
                        <QuillEditor
                          value={value}
                          onChange={onChange}
                          setEditorTextLength={setEditorTextLength}
                        />
                      )
                    }
                  />
                </td>
              </tr>
              <tr>
                <th className="text-center-title zoom_font">파일첨부</th>
                <td colSpan="5">
                  <span className="filetype">
                    <span className="file-select border-line mouse zoom_font">
                      <input
                        {...register("file")}
                        type="file"
                        multiple="multiple"
                        className="input-file"
                        onChange={onchangeFilesHandler}
                      />
                    </span>
                    <span className="file-btn zoom_font">+ 파일선택</span>

                    <div className="file-list">
                      {files.length > 0 &&
                        files.map((ele) => {
                          return (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                removeFile(ele.id);
                              }}
                              key={ele.id}
                              type="text"
                              className="file-text"
                            >
                              {ele.file.name}
                              <FileDeleteBtn />
                            </div>
                          );
                        })}
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <table className="writetable mobile-table">
          <tbody>
            <tr>
              <th className="text-center-title w-20">대상자</th>
              <td>
                <input
                  className="custom-input"
                  type="text"
                  placeholder="제목을 검색해주세요."
                  style={{ width: "60%" }}
                />
              </td>
            </tr>
          </tbody>
        </table> */}
        <div className="btn-group">
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="submit"
          >
            저장
          </button>
          <Link to={`/${auth.accountType}/workreport`}>
            <button
              className="writebtn cancelbtn border-line mouse zoom_font"
              type="button"
            >
              취소
            </button>
          </Link>
        </div>
      </form>
    </div>
  );
};

export default WorkReportWrite;
