import { Editor } from "@tinymce/tinymce-react";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { useRecoilState, useRecoilValue } from "recoil";
import { authAtom, toastAtom } from "../../../recoil";
import { useNavigate, useParams } from "react-router-dom";
import { useWorkReportActions } from "../../../recoil/api/workReport";
import BreadCrumb from "../BreadCrumb";
import { ReactComponent as FileDeleteBtn } from "../../../img/file-delete-btn.svg";
import { fileNameCheck, fileSizeCheck } from "../../../utils";
import { workReportmaxLength } from "./WorkReportWrite";
import { QuillEditor } from "../../toolbar/QuillEditor";

const WorkReportEdit = () => {
  const params = useParams();
  const navigate = useNavigate();

  // 계정 상태 값
  const auth = useRecoilValue(authAtom);

  // 토스트 상태 값
  const [toast, setToast] = useRecoilState(toastAtom);
  const [doubleclickLoading, setDoubleclickLoading] = useState(false);

  // workReportView 데이터 상태 값
  const [workReportData, setWorkReportData] = useState();

  // 파일 상태 값
  const [files, setFiles] = useState([]);
  const [prevFiles, setPrevFiles] = useState([]);
  const [toRemoveFilesId, setToRemoveFilesId] = useState([]);
  const [toRemainPrevFilesIndex, setToRemainPrevFilesIndex] = useState([]);

  // 파일 핸들러
  const onchangeFilesHandler = (event) => {
    event.preventDefault();
    if (event.target.files.length) {
      if (fileNameCheck(event) === false) {
        alert("파일명에 사용할 수 없는 특수문자가 들어가 있습니다.");
        return;
      }
      if (fileSizeCheck(event, 300) === false) {
        alert("첨부파일의 용량은 300MB를 넘길 수 없습니다.");
        return;
      }

      const newFiles = files
        .concat(Array.from(event.target.files))
        .map((ele, index) =>
          isNaN(ele?.id) ? { id: index, file: ele } : { ...ele, id: index }
        );
      setFiles(newFiles);
      setValue("file", null);
    }
  };

  // 함수 - 삭제할 파일 선택하기
  const removeFile = (index) => {
    const newFiles = [...files].filter((file) => file.id !== index);
    setFiles(newFiles);
  };

  // API 요청 함수 - workReportView 상세 데이터 불러오기
  const workReportActions = useWorkReportActions();

  const loadWorkReportView = async (docsn) => {
    const res = await workReportActions.getWorkReportView(docsn);

    if (res.status === 200) {
      setWorkReportData(() => res.data);
      setValue("editor", res.data.work_report_contents);
      setValue(
        "workreportYMD",
        dayjs(res.data?.work_report_ymd).format("YYYY-MM-DD")
      );
      setValue("work_report_title", res.data.work_report_title);
    }
  };

  // API 요청 함수 - workReportView 파일 URL 불러오기
  const loadPrevFiles = async (docsn) => {
    const res = await workReportActions.getWorkReportFileUrl(docsn);
    setPrevFiles(res.data);
    setToRemainPrevFilesIndex(Array(res?.data?.length).fill(true));
  };

  // useEffect
  useEffect(() => {
    loadWorkReportView({ docsn: params.sn });
    loadPrevFiles({ docsn: params.sn });
  }, []);

  // 에디터 글자 저장용도
  const [editorTextLength, setEditorTextLength] = useState(0);

  // Submit 함수
  const { register, handleSubmit, setValue, control } = useForm({
    defaultValues: {
      editor: "",
      workreportYMD: dayjs().format("YYYY-MM-DD"),
    },
  });

  const onSubmit = async (data) => {
    let filteredList = prevFiles.map((prevFile, index) => {
      if (toRemainPrevFilesIndex[index]) {
        return {
          filename: prevFile?.filename,
          extension: prevFile?.extension,
          url: prevFile?.url,
        };
      }
    });
    filteredList = filteredList.filter((item) => item !== undefined);

    if (
      editorTextLength < workReportmaxLength &&
      files.length + filteredList.length === 0
    ) {
      setToast({
        ...toast,
        error: true,
        message: "100자 이상 기재와 첨부파일 등록을 부탁드립니다.",
      });
      return;
    } else if (editorTextLength < workReportmaxLength) {
      setToast({
        ...toast,
        error: true,
        message: "100자 이상 기재 부탁드립니다.",
      });
      return;
    } else if (files.length + filteredList.length === 0) {
      setToast({
        ...toast,
        error: true,
        message: "첨부파일이 등록되지 않았습니다.",
      });
      return;
    }

    if (doubleclickLoading === true) {
      return;
    }
    setDoubleclickLoading(true);

    // 기존 파일 삭제
    for (let i = 0; i < toRemoveFilesId.length; i++) {
      await workReportActions.deleteWorkReportFile({
        sn: toRemoveFilesId[i],
      });
      // 기존 파일 정보도 같이 보내야 함!!
    }
    const req = {
      title:
        data?.work_report_title?.trim() === ""
          ? "[제목없음]"
          : data?.work_report_title,
      docsn: params.sn,
      YMD: dayjs(data.workreportYMD).format("YYYYMMDD"),
      employ_id: workReportData?.work_report_user_id,
      type: workReportData.type,
      contents: data.editor,
      file: files,
      prevFiles: prevFiles.map((prevFile, index) => {
        if (toRemainPrevFilesIndex[index]) {
          return {
            filename: prevFile?.filename,
            extension: prevFile?.extension,
            url: prevFile?.url,
          };
        }
      }),
    };

    // update 요청
    const res = await workReportActions.updateWorkReport(req);

    if (res.status === 200) {
      setToast({ ...toast, on: true });
      navigate(`/${auth.accountType}/workreport`);
    } else {
      setToast({ ...toast, error: true, message: "작성 실패" });
    }

    setDoubleclickLoading(false);
  };

  // 렌더링
  return (
    <div className="substanceCont">
      <BreadCrumb />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="table-responsive">
          <table className="writetable hide">
            <tbody>
              <tr>
                <th className="text-center-title zoom_font">작성자</th>
                <td> {workReportData?.id}</td>
                <th className="text-center-title zoom_font">작성일</th>
                <td>
                  <input
                    {...register("workreportYMD", {})}
                    className="write-date border-line mouse zoom_font"
                    type="date"
                  />
                </td>
              </tr>
              <tr style={{ display: "none" }}>
                <th className="text-center-title zoom_font">제목</th>
                <td colSpan="3" className="zoom_font">
                  <input
                    {...register("work_report_title", {})}
                    className="write-input border-line mouse zoom_font"
                    type="text"
                  />
                </td>
              </tr>

              <tr>
                <th className="text-center-title zoom_font">
                  내용
                  <div>{`${editorTextLength}/${workReportmaxLength}`}</div>
                </th>
                <td colSpan="5">
                  <Controller
                    name="editor"
                    control={control}
                    render={({ field: { value, onChange } }) =>
                      auth.user?.disabled_name == "시각장애" ||
                      auth.user?.sub_disabled_name == "시각장애" ? (
                        <Editor
                          apiKey={
                            "pwlbxd7c7x9lapnd8nwqidzm2gre8bazisoovnitdbojymrp"
                          }
                          onGetContent={(e) => {
                            let content = e.content;
                            content = content
                              .replace(/(<([^>]+)>)/gi, "")
                              .replace(/&lt;/gi, "1")
                              .replace(/&gt;/gi, "1")
                              .replace(/&nbsp;/gi, "1")
                              .replace(/&quot;/gi, "1")
                              .replace(/&amp;/gi, "1");

                            setEditorTextLength(content.length);
                          }}
                          value={value}
                          onEditorChange={onChange}
                          init={{
                            plugins: [
                              "table",
                              "link",
                              "autolink",
                              "contextmenu",
                            ],
                            language: "ko_KR",
                            selector: "div",
                            elementpath: false,
                            branding: false,
                            autolink: true,
                            toolbar:
                              "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help | link",
                            contextmenu: "true",
                            menubar: "file insert view format table",
                          }}
                        />
                      ) : (
                        <QuillEditor
                          value={value}
                          onChange={onChange}
                          setEditorTextLength={setEditorTextLength}
                        />
                      )
                    }
                  />
                </td>
              </tr>
              {/* 파일 input */}
              <tr>
                <th className="text-center-title zoom_font">파일첨부</th>
                <td colSpan="5">
                  <span className="filetype">
                    <span className="file-select border-line mouse zoom_font">
                      <input
                        {...register("file")}
                        type="file"
                        multiple="multiple"
                        className="input-file"
                        onChange={onchangeFilesHandler}
                      />
                    </span>
                    <span className="file-btn">+ 파일선택</span>
                    <div className="file-list">
                      {/* 이전에 저장된 파일 목록 */}
                      {prevFiles?.map((prevFile, index) => {
                        return toRemainPrevFilesIndex[index] ? (
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setToRemainPrevFilesIndex(() => {
                                const newIndexArray = [
                                  ...toRemainPrevFilesIndex,
                                ];
                                newIndexArray[index] = !newIndexArray[index];
                                return newIndexArray;
                              });
                              if (toRemoveFilesId.includes(prevFile.sn)) {
                                setToRemoveFilesId(
                                  [...toRemoveFilesId].filter(
                                    (ele) => ele !== prevFile.sn
                                  )
                                );
                              } else {
                                setToRemoveFilesId([
                                  ...toRemoveFilesId,
                                  prevFile.sn,
                                ]);
                              }
                            }}
                            key={prevFile.sn}
                            type="text"
                            className="file-text"
                          >
                            {`${prevFile?.filename}.${prevFile?.extension}`}
                            <FileDeleteBtn />
                          </div>
                        ) : (
                          <div
                            style={{
                              textDecoration: "line-through",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setToRemainPrevFilesIndex(() => {
                                const newIndexArray = [
                                  ...toRemainPrevFilesIndex,
                                ];
                                newIndexArray[index] = !newIndexArray[index];
                                return newIndexArray;
                              });

                              if (toRemoveFilesId.includes(prevFile.sn)) {
                                setToRemoveFilesId(
                                  [...toRemoveFilesId].filter(
                                    (ele) => ele !== prevFile.sn
                                  )
                                );
                              } else {
                                setToRemoveFilesId([
                                  ...toRemoveFilesId,
                                  prevFile.sn,
                                ]);
                              }
                            }}
                            key={prevFile.sn}
                            type="text"
                            className="file-text"
                          >
                            {`${prevFile?.filename}.${prevFile?.extension}`}
                            <FileDeleteBtn />
                          </div>
                        );
                      })}
                      {/* 새로 업로드하는 파일 목록 */}
                      {files.length > 0 &&
                        files.map((ele) => {
                          return (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                removeFile(ele.id);
                              }}
                              onChange={() => {}}
                              key={ele.id}
                              type="text"
                              className="file-text"
                            >
                              {ele.file.name}
                              <FileDeleteBtn />
                            </div>
                          );
                        })}
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <table className="writetable mobile-table">
          <tbody>
            <tr>
              <th className="text-center-title w-20">대상자</th>
              <td>
                <input
                  className="custom-input"
                  type="text"
                  placeholder="제목을 검색해주세요."
                  style={{ width: "60%" }}
                />
              </td>
            </tr>
          </tbody>
        </table> */}
        <div className="btn-group">
          <button
            className="writebtn submitbtn border-line mouse zoom_font"
            type="submit"
          >
            저장
          </button>

          <button
            className="writebtn cancelbtn border-line mouse zoom_font"
            type="button"
            onClick={() => navigate(-1)}
          >
            취소
          </button>
        </div>
      </form>
    </div>
  );
};

export default WorkReportEdit;
